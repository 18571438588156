import React, { useContext } from 'react';
import { ThemeContext } from '../context/theme';
import SunIcon from '../assets/svg/sun.inline.svg';
import MoonIcon from '../assets/svg/moon.inline.svg';

const DarkModeButton = () => {
  const { theme, setTheme } = useContext(ThemeContext);

  let iconSwitch;

  const onClickTheme = () => {
    setTheme(theme === 'dark' ? 'light' : 'dark');
  };

  const buttonSwitch = (
    <div className="my-auto aspect-square h-5 w-5 rounded-full bg-white text-xl shadow-md" />
  );

  if (theme === 'light') {
    iconSwitch = (
      <MoonIcon className="h-5 text-xl leading-none text-slate-700 dark:text-slate-500" />
    );
  }

  if (theme === 'dark') {
    iconSwitch = (
      <SunIcon className="h-5 text-xl leading-none text-slate-400 dark:text-yellow-500" />
    );
  }

  const singleSwitch = (
    <button
      type="button"
      className={`relative flex w-[53px] justify-between rounded-full bg-slate-300 p-1 dark:bg-slate-700 ${
        theme === 'light' ? 'flex-row' : 'flex-row-reverse'
      }`}
      onClick={onClickTheme}
      aria-label="Dark Mode"
    >
      {iconSwitch}
      {buttonSwitch}
    </button>
  );

  return singleSwitch;
};

export default DarkModeButton;
