import React, { useState } from 'react';
import { useBreakpoint } from 'gatsby-plugin-breakpoints';
import UniversalLink from '../components/UniversalLink';
import DarkModeButton from '../components/DarkModeButton';
import { SITE_CONFIG } from '../config/constants';

const Menu = () => {
  const navItems = SITE_CONFIG['navLinks'];
  const breakpoints = useBreakpoint();
  const [isOpen, setIsOpen] = useState(false);
  let className = isOpen ? 'nav-mobile-active translate-x-0' : '';

  const handleClick = () => {
    setIsOpen(!isOpen);
  };

  if (breakpoints.md) {
    className = '';
  }

  return (
    <nav className="mt-3 flex justify-end gap-6">
      <ul className={`primary-nav m-0 list-none p-0 ${className}`}>
        <li className="text-right md:hidden">
          <button
            type="button"
            className="mr-3 text-base font-semibold text-slate-800 after:ml-1 after:content-['×'] dark:text-slate-50"
            onClick={breakpoints.md ? null : handleClick}
          >
            Close
          </button>
        </li>
        {navItems.map(({ label, url }) => (
          <li
            className="my-auto border-b border-slate-200 dark:border-slate-700 md:border-0"
            onClick={breakpoints.md ? null : handleClick}
            onKeyDown={breakpoints.md ? null : handleClick}
            key={url}
          >
            <UniversalLink
              to={url}
              className="my-auto block p-5 text-base text-slate-800 hover:text-slate-900 dark:text-slate-400 dark:hover:text-slate-300 md:p-0"
            >
              {label}
            </UniversalLink>
          </li>
        ))}
      </ul>
      <DarkModeButton />
      <button
        type="button"
        className="block text-base font-semibold text-slate-800 before:mr-1 before:content-['☰'] dark:text-slate-50 md:hidden"
        onClick={handleClick}
      >
        Menu
      </button>
    </nav>
  );
};

export default Menu;
