import React from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '../context/theme';
import Header from './Header';
import Footer from './Footer';

const LayoutInner = ({ children, location }) => {
  return (
    <div className="px-3 md:container md:mx-auto">
      <Header />
      <main>{children}</main>
      <Footer />
    </div>
  );
};

LayoutInner.propTypes = {
  children: PropTypes.node.isRequired,
};

const Layout = (props) => {
  return (
    <ThemeProvider>
      <LayoutInner {...props} />
    </ThemeProvider>
  );
};

export default Layout;
