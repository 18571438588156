import React from 'react';
import UniversalLink from '../components/UniversalLink';
import Menu from '../components/Menu';
import { SITE_CONFIG } from '../config/constants';

const Header = () => {
  const siteName = SITE_CONFIG['siteName'];

  return (
    <>
      <Menu />
      <header className="flex justify-between pt-3 pb-9">
        <UniversalLink className="text-xl tracking-wide hover:no-underline" to="/">
          <span className="font-semibold text-slate-800 dark:text-white">{siteName}</span>
        </UniversalLink>
      </header>
    </>
  );
};

export default Header;
